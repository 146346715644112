/* stylelint-disable selector-class-pattern */
.container {
    --header-height: 0px;

    position: relative;
    min-height: 100vh;
    min-height: 100svh;
    background: #fff;

    a > img {
        display: block;
    }

    a {
        text-decoration: none;
    }

    h1 {
        font-family: var(--font-garage);
    }
}
